import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { reject } from '../../lib/nodash';
import ProductBadges from './ProductBadges';
import useProductFromContextOrPicker from './useProductFromContextOrPicker';
import { getMetafieldTags } from '../../lib/product';
import useMobile from '../useMobile';

const DynamicProductBadges = ({
  blok: { collection, product, size, mobileSize },
}) => {
  const { productCollection } = useProductFromContextOrPicker({
    collection,
    product,
  });
  const isMobile = useMobile();
  const tags = productCollection
    ? getMetafieldTags(productCollection.metafields)
    : product.tags;
  return (
    <ProductBadges
      tags={reject((x) => x === 'badge:new', tags)}
      size={isMobile ? mobileSize || size : size}
    />
  );
};

DynamicProductBadges.propTypes = {
  blok: PropTypes.shape({
    product: PropTypes.object,
    collection: PropTypes.object,
    size: PropTypes.string,
    mobileSize: PropTypes.string,
  }),
};

export default memo(DynamicProductBadges);
